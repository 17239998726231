<template>
  <div class="footer-area bg-none pb-60 mt-60 footer-bottom-wrapper">
    <div class="container-fluid">
      <div class="row row-flex row-middle flex-middle">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div class="block-wrapper text-left flex flex-wrap">
            <!--           <a :href="getUrl()">-->
            <img
              class="logo-footer"
              :src="require('../assets/img/imgs/logo3.png')"
              alt=""
            />
            <!--           </a>-->
            <!--           <a href="https://binnies.com/sectors/water/">-->
            <img
              class="logo-footer"
              :src="require('../assets/img/imgs/logo2.png')"
              alt=""
            />
            <!--           </a>-->
            <!--           <a :href="getUrl1()">-->
            <img
              class="logo-footer"
              :src="require('../assets/img/imgs/logo1.png')"
              alt=""
            />
            <!--           </a>-->
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div class="bottom-menu">
            <a href="#" target="_blank" style="color: #007bff">{{getBanquanText()}}</a> |
            <a href="#" target="_blank" style="color: #007bff">{{getMianzeText()}}</a>
            <!--            <a href="#" target="_blank" style="color: #007bff;">无障碍声明</a>&nbsp;-->
            <!--            <a href="http://www.w3.org/WAI/WCAG2AA-Conformance" target="_blank">-->
            <!--              <img src="../assets/img/imgs/w3c.png" alt="Image ALT" title="Image TITLE">-->
            <!--            </a>-->
          </div>
          <!--          <div class="copyright ignore">-->
          <!--            ©<span class="current-year">2024</span>-->
          <!--            WSD All rights reserved. Powered by <a class="font-12" href="#" target="_blank" style="color: #007bff;">Guizhou Huizhi Electronics Co. , Ltd.</a>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { getConfig } from "@/axios/api";
export default {
  name: "footerComponent",
  data() {
    return {
      footer: {},
      banquan:'版权公告',
      mianze:'免责声明'
    };
  },
  created() {},
  mounted() {
    this.getConfig();
    window.translate.execute()
  },
  methods: {
    getMianzeText () {      
      var btnText = this.mianze
      const lang = window.translate.language.getCurrent()
      if (lang === 'english') {
        btnText = 'Disclaimer'
      }
      if (lang === 'chinese_traditional') {
        btnText = '免責聲明'
      }
      return btnText
    },
    getBanquanText () {
      var btnText = this.banquan
      const lang = window.translate.language.getCurrent()
      if (lang === 'english') {
        btnText = 'Copyright Notice'
      }
      if (lang === 'chinese_traditional') {
        btnText = '版權公告'
      }
      return btnText
    },
    getConfig() {
      if (sessionStorage.getItem("pageConfig")) {
        const data = JSON.parse(sessionStorage.getItem("pageConfig"));
        this.footer = data.configs;
        window.translate.execute()
        // this.trans();
        return;
      }
      getConfig().then((res) => {
        this.footer = res.data.configs;
        window.translate.execute()
        // this.trans();
      });
    },
    getUrl() {
      let str = "";
      const lang = window.translate.language.getCurrent();
      if (lang === "english") {
        str = "https://www.dsd.gov.hk/EN/Home/index.html";
      }
      if (lang === "chinese_simplified") {
        str = "https://www.dsd.gov.hk/SC/Home/index.html";
      }
      if (lang === "chinese_traditional") {
        str = "https://www.dsd.gov.hk/TC/Home/index.html";
      }
      return str;
    },
    getUrl1() {
      let str = "";
      const lang = window.translate.language.getCurrent();
      if (lang === "english") {
        str = "https://cscechk.com/en/index.php";
      }
      if (lang === "chinese_simplified") {
        str = "https://cscechk.com/tc/index.php";
      }
      if (lang === "chinese_traditional") {
        str = "https://cscechk.com/tc/index.php";
      }
      return str;
    },
    trans() {
      this.$nextTick(() => {
        // window.translate.request.api.host = "https://tr.yuenlongbarrage.hk/"; // 将这里面的ip地址换成你服务器的ip，注意开头，及结尾还有个 / 别拉下
        // // 指定翻译服务为使用 translate.service
        // window.translate.execute(); // 进行翻译
      });
    },
  },
};
</script>
<style lang="less" scoped>
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area .copywrite-area {
    border-top: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .footer-area .copywrite-area {
    border-top: none !important;
  }
}

#sendMail {
  color: #000;
}
.block-wrapper {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: 0;
}
.bottom-menu {
  text-align: inherit;
  color: #666;
}
.copyright {
  text-align: inherit;
  color: #666;
  font-size: 12px !important;
  min-height: 10%;
}
.flex-middle {
  align-items: center !important;
  align-content: center !important;
}
.row-flex {
  margin-right: -15px;
  margin-left: -15px;
}
.row-flex {
  display: flex;
  flex-wrap: wrap;
}
.footer-bottom-wrapper {
  font-size: 0.667rem;
  text-align: right;
  padding: 2.5rem 0;
  background-color: #fff;
}
</style>
