<template>
  <!--  :style="{minWidth:isMobile?'1920px':''}"-->
  <div id="app">
    <Header></Header>
    <router-view :key="$route.fullPath" />
    <div
      class="modal fade"
      tabindex="-1"
      style="background: #ffffff"
      :class="{ show: store.isLoading, 'd-block': store.isLoading }"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="position: relative"
      >
        <div
          style="
            position: absolute;
            top: 38%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 24rpx;
            width: 300px;
          "
        >
          <img src="https://www.yuenlongbarrage.hk/img/logo3.6a92196e.png" />
        </div>
        <div
          class="modal-content"
          style="border: none; background-color: transparent"
        >
          <div class="spinner-border mx-auto" role="status"></div>
        </div>
      </div>
    </div>
    <div v-if="store.isLoading" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
/* eslint-disable */
import Header from "@/components/header";
import { stores } from "@/utils/store.js";
export default {
  data() {
    return {
      isMobile: false,
      store: stores,
    };
  },
  components: {
    Header,
  },
  watch: {
    "$store.state.scrollTop"(newval) {
      this.$store.commit("setScrollTop", newval);
    },
    $route (to, from) {
      this.openLoading()
      console.log('wathch', to,from)
    }
  },

  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    // localStorage.setItem('to', 'english')//英

    // localStorage.setItem('to', 'chinese_simplified')//简体
    
    let to = localStorage.getItem('to');

    console.log('app.vue to=',to )
    // 检测 localStorage 中是否存在 'to' 键
    if (!to) {
        // 如果 'to' 不存在，则设置默认值为 'chinese_simplified'
        localStorage.setItem('to', 'english'); // 简体
        // localStorage.setItem('to', 'chinese_simplified'); // 简体
    } 

    // localStorage.setItem('to', 'chinese_traditional')//繁体
  },
  created() {
    // localStorage.clear()
    // console.log(this.$store.state.lang)

    //特别注意：打包需要分为3种语言，3次
    // localStorage.setItem('to', this.$store.state.lang)//繁体打包
    // localStorage.setItem('to', 'chinese_simplified')//简体
    // localStorage.setItem('to', 'chinese_traditional')//繁体
    // localStorage.setItem('to', 'english')//英文EN打包

    let to = localStorage.getItem('to');

    console.log('app.vue to=',to )
    // 检测 localStorage 中是否存在 'to' 键
    if (!to) {
        // 如果 'to' 不存在，则设置默认值为 'chinese_simplified'
        localStorage.setItem('to', 'chinese_simplified'); // 简体
    } 

  },
  methods: {
    openLoading() {
      stores.updateLoadingStatue(true);
      setTimeout(() => {
        stores.updateLoadingStatue(false);
      }, 3000);
    },
    scrollToTop() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.$store.commit("setScrollTop", scrollTop);
    },
    getEnv() {
      const userAgent = window.navigator.userAgent;
      if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone/i.test(userAgent)) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
</script>
<style>
html,
body,
#app {
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  position: relative;
}
#app {
  height: 100vh;
}

html[lang="en-GB"] body {
  font-family: Helvetica, Arial, "Microsoft JhengHei", 微軟正黑體,
    "Microsoft YaHei", 微软雅黑体, PMingLiU, 新細明體, 宋体, simsun, sans-serif;
}
</style>
