var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-drawer',{ref:"drawer",attrs:{"visible":_vm.drawerShow,"direction":_vm.direction,"append-to-body":true,"size":"82%"},on:{"update:visible":function($event){_vm.drawerShow=$event},"close":_vm.close}},[_c('div',[_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"router":"","background-color":"#27273D","default-active":_vm.$route.path},on:{"select":_vm.close}},[_vm._l((_vm.menuList),function(menuItem,menuIndex){return [(menuItem.child)?_c('el-submenu',{key:menuItem.id,attrs:{"index":menuIndex + 'submenu'}},[_c('template',{slot:"title"},[(menuItem.litpic.length > 0 && menuItem.child)?_c('img',{key:menuItem.nav_url + 'icon',staticClass:"icon-20",attrs:{"src":_vm.baseUrl + menuItem.litpic,"alt":""}}):_vm._e(),_c('span',{staticClass:"text-white margin-lr-10"},[_vm._v(_vm._s(_vm.transHeader(menuItem)))])]),_vm._l((menuItem.child),function(child,chidIndex){return [_c('el-menu-item',{key:child.id,attrs:{"index":chidIndex + 'child',"route":{
                    path:
                      child.nav_url === '/main'
                        ? child.nav_url + `/${child.type_id}`
                        : child.nav_url,
                    query: {
                      type_id: child.type_id,
                      target: child.target,
                      tab: child.nav_name,
                      headpic: child.headpic,
                      headmov: child.headmov,
                    },
                  }}},[(child.litpic.length > 0)?_c('img',{staticClass:"icon-20",attrs:{"src":_vm.baseUrl + child.litpic,"alt":""}}):_vm._e(),_c('span',{staticClass:"text-white margin-lr-10"},[_vm._v(_vm._s(_vm.transHeader(child)))])])]})],2):(menuItem.nav_url === '/')?_c('el-menu-item',{key:menuItem.id,attrs:{"index":"/","route":{ path: `/`}}},[_c('div',{staticClass:"text-center"},[(menuItem.litpic.length > 0)?_c('img',{staticClass:"icon-32",attrs:{"src":_vm.baseUrl + menuItem.litpic,"alt":""}}):_vm._e()])]):_c('el-menu-item',{key:menuItem.id,attrs:{"index":menuIndex + 'menu',"route":{
                path:
                  menuItem.nav_url === '/main'
                    ? menuItem.nav_url + `/${menuItem.type_id}`
                    : menuItem.nav_url,
                query: {
                  type_id: menuItem.type_id,
                  target: menuItem.target,
                  tab: menuItem.nav_name,
                  headpic: menuItem.headpic,
                  headmov: menuItem.headmov,
                },
              }}},[(menuItem.litpic.length > 0)?_c('img',{staticClass:"icon-20",attrs:{"src":_vm.baseUrl + menuItem.litpic,"alt":""}}):_vm._e(),_c('span',{staticClass:"text-white margin-lr-10"},[_vm._v(_vm._s(_vm.transHeader(menuItem)))])])]}),_c('el-menu-item',[_c('div',{staticClass:"ignore text-center"},[_c('span',{staticClass:"text-white",attrs:{"id":"translateCs"},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.changeLanguage1('chinese_simplified')}}},[_vm._v("简")]),_c('span',{staticClass:"margin-lr-10 text-white"},[_vm._v("|")]),_c('span',{staticClass:"text-white",attrs:{"id":"translateSpanTc"},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.changeLanguage1('chinese_traditional')}}},[_vm._v("繁")]),_c('span',{staticClass:"margin-lr-10 text-white"},[_vm._v("|")]),_c('span',{staticClass:"text-white",attrs:{"id":"translateSpanEn"},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.changeLanguage1('english')}}},[_vm._v("En")])])])],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }