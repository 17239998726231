import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    scrollTop: 0,
    lang: "english",
  },
  getters: {
    getPageConfig(state, data) {
      this.setPageConfig(data);
    },
    getLang(state) {
      return state.lang;
    },
  },
  mutations: {
    setScrollTop(state, scroll) {
      state.scrollTop = scroll;
    },
    setLang(state, lang) {
      console.log("setLang", lang);
      state.lang = lang;
    },
  },
  actions: {},
  modules: {},
});
export default store;
