<template>
  <div>
    <el-drawer
      :visible.sync="drawerShow"
      :direction="direction"
      :append-to-body="true"
      size="82%"
      @close="close"
      ref="drawer"
    >
      <div>
        <el-menu
          router
          background-color="#27273D"
          :default-active="$route.path"
          @select="close"
          class="el-menu-vertical-demo"
        >
          <template v-for="(menuItem, menuIndex) in menuList">
            <el-submenu
              v-if="menuItem.child"
              :key="menuItem.id"
              :index="menuIndex + 'submenu'"
            >
              <template slot="title">
                <img
                  v-if="menuItem.litpic.length > 0 && menuItem.child"
                  :key="menuItem.nav_url + 'icon'"
                  :src="baseUrl + menuItem.litpic"
                  alt=""
                  class="icon-20"
                />
                <span class="text-white margin-lr-10">{{
                    transHeader(menuItem)
                }}</span>
              </template>
              <template v-for="(child, chidIndex) in menuItem.child">
                <el-menu-item
                  :key="child.id"
                  :index="chidIndex + 'child'"
                  :route="{
                    path:
                      child.nav_url === '/main'
                        ? child.nav_url + `/${child.type_id}`
                        : child.nav_url,
                    query: {
                      type_id: child.type_id,
                      target: child.target,
                      tab: child.nav_name,
                      headpic: child.headpic,
                      headmov: child.headmov,
                    },
                  }"
                >
                  <img
                    v-if="child.litpic.length > 0"
                    :src="baseUrl + child.litpic"
                    alt=""
                    class="icon-20"
                  />
                  <span class="text-white margin-lr-10">{{
                      transHeader(child)
                  }}</span>
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item
              v-else-if="menuItem.nav_url === '/'"
              :key="menuItem.id"
              index="/"
              :route="{ path: `/`}"
            >
              <div class="text-center">
                <img
                  v-if="menuItem.litpic.length > 0"
                  :src="baseUrl + menuItem.litpic"
                  alt=""
                  class="icon-32"
                />
              </div>
            </el-menu-item>
            <el-menu-item
              v-else
              :key="menuItem.id"
              :index="menuIndex + 'menu'"
              :route="{
                path:
                  menuItem.nav_url === '/main'
                    ? menuItem.nav_url + `/${menuItem.type_id}`
                    : menuItem.nav_url,
                query: {
                  type_id: menuItem.type_id,
                  target: menuItem.target,
                  tab: menuItem.nav_name,
                  headpic: menuItem.headpic,
                  headmov: menuItem.headmov,
                },
              }"
            >
              <img
                v-if="menuItem.litpic.length > 0"
                :src="baseUrl + menuItem.litpic"
                alt=""
                class="icon-20"
              />
              <span class="text-white margin-lr-10">{{
                  transHeader(menuItem)
              }}</span>
            </el-menu-item>
          </template>
          <el-menu-item>
            <div class="ignore text-center">
              <span
                class="text-white"
                id="translateCs"
                @click.self="changeLanguage1('chinese_simplified')"
                >简</span>
<!--              <a class="text-white" href="javascript:changeLanguage1('chinese_simplified');">简</a>-->
              <span class="margin-lr-10 text-white">|</span>
              <span
                class="text-white"
                id="translateSpanTc"
                @click.self="changeLanguage1('chinese_traditional')"
                >繁</span
              >
<!--              <a class="text-white" href="javascript:changeLanguage1('chinese_traditional');">繁</a>-->
              <span class="margin-lr-10 text-white">|</span>
              <span
                class="text-white"
                id="translateSpanEn"
                @click.self="changeLanguage1('english')"
                >En</span
              >
<!--              <a class="text-white" href="javascript:changeLanguage1('english');">En</a>-->
            </div>
          </el-menu-item>
        </el-menu>
      </div>
    </el-drawer>
  </div>
</template>

<script>
/* eslint-disable */
import {stores} from '@/utils/store'

export default {
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    menuList: {
      type: Array,
    },
  },
  data() {
    return {
      direction: "ltr",
      drawerShow: false,
      baseUrl: "https://admin.yuenlongbarrage.hk/",
      lang:''
    };
  },
  watch: {
    drawer: {
      handler(val) {
        this.drawerShow = val;
      },
    },
  },
  mounted() {
    this.$refs.drawer.rendered = true;
    this.drawerShow = this.drawer;
    this.lang = stores.getLang(window.translate.language.getCurrent())
    window.translate.execute()
    // window.translate.changeLanguage(window.translate.language.getCurrent());
    const _that = this
    window.translate.selectLanguageTag.selectOnChange = async function (event) {
      console.log('change')
      return
      let language = event.target.value;
      window.translate.changeLanguage(language);
      window.translate.execute()
      await stores.updateRoute(language)
      // _that.$forceUpdate()
      // _that.openLoading();
    };
    this.trans();
  },
  computed: {
    transHeader() {
      return function (data) {
        let str = "";
        const lang = window.translate.language.getCurrent();
        if (lang === "english") {
          str = data.en_name;
        } else if (lang === "chinese_traditional") {
          str = data.tc_name;
        } else {
          str = data.nav_name;
        }
        // console.log('tete', str, lang)
        return str;
      };
    },
  },
  methods: {
    close() {
      this.drawerShow = false;
      this.$emit("close", false);
      window.translate.execute()
    },
    async changeLanguage1(lang) {
     window.translate.changeLanguage(lang);
     window.translate.execute()
     await stores.updateRoute(lang)
     // this.$forceUpdate()

    },
    trans() {
      // window.translate.language.setDefaultTo("chinese_traditional");
      window.translate.nomenclature.append(
        "chinese_simplified",
        "chinese_traditional",
        `
      联系我们=聯繫我們
      工程热线=工程熱線
      元朗防洪坝及明渠改善计划=元朗防洪壩及明渠改善計劃
      地盘写字楼地址=地盤寫字樓地址
      电话=電話
      电子邮件=電子郵件
      社区联系=社區聯繫
      资讯中心=資訊中心
      项目进展=項目進展
      研讨会和会议=研討會和會議
      环境影响评估=環境影響評估
      社区参与=社區參與
      区议会资询=區議會咨詢
      公共参与=公眾參與
      工程项目=工程項目
      宣传短片=宣傳短片
      短片=短片
      宣傳短片=宣傳短片
      宣傳片=宣傳短片
      项目展板=項目展板
      工地参观=工地參觀
      工地安全=工地安全
      纪录片=記錄片
      一般=一般
      `
      );
      window.translate.nomenclature.append(
        "chinese_simplified",
        "english",
        `
      工地安全=Safety
      纪录片=Documentary
      一般=General
      区议会资询=Consultation with District Council
      社区参与=Community Engagement
      中建=China State
      爱铭联营体=Alchmex Joint Venture
      中国建筑工程=China State Construction Engineering
      香港=Hong Kong
      有限公司=Limited
      爱铭建筑=Alchmex International Construction
      爱铭=Alchmex
      建筑=Construction
      国际=International
      有限公司联营=Limited Joint Venture
      社区协助=Community
      工程项目=Project
      工程进度=Progress
      背景=Background
      环境影响评估=EM&A Information
      工程图片=Progress pictures
      常见问题=FAQ
      社区参与活动=Community Engagement Activity
      Community Engagement Activities=Community Engagement Activity
      参与活动=Engagement
      活动=Activity
      建造工程通讯=Newsletter
      资料库=Information Center
      公共参与=Public Engagement
      海水化淡技术=Desalination
      项目介绍=Project Information
      主要项目=Major Activities
      奖项=Awards
      海水化淡技术与原理=How Does Desalination Work?
      逆渗透技术=RO Technology
      宣传短片=Publicity Video
      项目展板=Exhibition Panel
      区议会谘询=Consultation with District Council
      工地参观=Site Visit
      研讨会和会议=Seminar and Conference
      特别项目=Special Features
      最新消息=Latest News
      工程通讯=Newsletters
      即将来临=Coming Soon
      马上就来=Coming Soon
      工程熱線=Project Hotline
      电子邮件=Email
      邮寄地址=Address
      元 朗=Yuen Long
      元=Yuen
       朗=Long
       即将推出=Coming soon
       联系我们=Contact us
       地盘写字楼地址=Site office address
       电话=Telephone
       社区联系=Community Liaison
       资讯中心=Information Center
        项目进展=Project progress
        项目背景=Project Background
       元朗防洪坝及明渠改善计划= Yuen Long Barrage and Nullah Improvement Schemes
        中建 – 爱铭联营体 (中国建筑工程(香港)有限公司 – 爱铭建筑(国际)有限公司联营) = China State – Alchmex Joint Venture  (China State Construction Engineering (Hong Kong )Limited – Alchmex International Construction Limited Joint Venture )
      `
      );
      window.translate.listener.start();
      window.translate.request.api.host = "https://tr.yuenlongbarrage.hk/"; // 将这里面的ip地址换成你服务器的ip，注意开头，及结尾还有个 / 别拉下
      // 指定翻译服务为使用 translate.service
      window.translate.execute(); // 进行翻译
    },
  },
};
</script>

<style scoped lang="less">
.nav-item > a * {
  display: inline-block;
}
.el-menu-item * {
  display: inline;
}
.el-submenu__title * {
  display: inline;
}
/deep/ .el-drawer {
  background-color: #27273d;
}
/deep/ .el-menu {
  border-right: none !important;
}
.translate {
  padding: 0 8px;
}
</style>
