<template>
  <div>
    <section class="text-center bg-img  bg-height-280 ignore">
      <div class="container-fluid h-100 bg-img video-box ignore">
        <video class="video-background" :poster="bg" :src="bgVideo" loop  muted="muted" autoplay="autoplay"></video>
        <div class="layer">
        </div>
        <div class="row h-100 align-items-center">
          <div class="col-12">
            <div class="breadcrumb-content pt-32">
              <p class="text-transform item-title">{{title}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'bGroundComponent',
  props: {
    bg: {
      type: String,
      default: require('../assets/img/imgs/bg4.png')
    },
    title: String,
    bgVideo: {
      type: String
    }
  },
  computed: {
    getTitle () {
      return function (title) {
        let str
        const lang = window.translate.language.getCurrent()
        if (lang === 'chinese_traditional') {
          str = '宣傳短片'
        } else if (lang === 'english') {
          str = 'Publicity Video'
        } else {
          str = title
        }
        return str
      }
    },
    getTitle2 () {
      return function (title) {
        let str
        const lang = window.translate.language.getCurrent()
        if (lang === 'chinese_traditional') {
          str = '環境影響評估'
        } else if (lang === 'english') {
          str = 'EM&A Information '
        } else {
          str = title
        }
        return str
      }
    },
    getTitle3 () {
      return function (title) {
        let str
        const lang = window.translate.language.getCurrent()
        if (lang === 'chinese_traditional') {
          str = '區議會諮詢'
        } else if (lang === 'english') {
          str = 'Consultation with District Council'
        } else {
          str = title
        }
        return str
      }
    }
  },
  data () {
    return {
      isMobile: false
    }
  },
  mounted () {
    const _that = this
    _that.getEnv()

    // console.log( 'title=====',this.title )
    // this.title = localStorage.getItem('title')

    // window.translate.execute()
    // document.getElementById('translate').addEventListener('change', (res) => {
    //   _that.$forceUpdate()
    // })
    // if (this.isMobile) {
    //   document.getElementById('translateCs').addEventListener('click', (res) => {
    //     _that.$forceUpdate()
    //   })
    //   document.getElementById('translateSpanTc').addEventListener('click', (res) => {
    //     _that.$forceUpdate()
    //   })
    //   document.getElementById('translateSpanEn').addEventListener('click', (res) => {
    //     _that.$forceUpdate()
    //   })
    // }
  },
  methods: {
    getEnv () {
      const userAgent = window.navigator.userAgent
      if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone/i.test(userAgent)) {
        this.isMobile = true
        console.log('移动端')
      } else {
        this.isMobile = false
        console.log('pc端')
      }
    }
  }

}
</script>

<style scoped lang="less">
.video-box {
  position: relative;
  height: 100%;
  background-color: #C1CFF7;
  overflow: hidden;
}

.video-box .video-background {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 800px;
}

.layer{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 179,170, 0.3);
}
.item-title{
  font-size: 3rem;
  font-weight: 500;
  color: inherit;
  text-shadow: 0 0 10px #000;
  color: #FFF;
  font-family: "Noto Sans SC", "Microsoft JhengHei","å¾®è»Ÿæ­£é»‘é«”","Microsoft YaHei","å¾®è½¯é›…é»‘ä½“",Helvetica,Arial,PMingLiU,"æ–°ç´°æ˜Žé«”","å®‹ä½“",simsun, sans-serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .item-title{
    font-size: 1.5rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .item-title{
    font-size: 2rem !important;
  }
}

</style>
