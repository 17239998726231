import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index'
Vue.use(VueRouter)
import { stores } from "../utils/store.js";
const routes = [
  {
    path: '/login',
    name: 'login',
    component: ()=>import('../views/login'),
    meta:{
      tab:''
    }
  },
  {
    path: '/',
    name: 'home',
    component: index,
    redirect:`/index`,
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('../views/home')
      },
      {
        path: 'gcbj',
        name: 'gcbj',
        component: () => import('../views/gcbj'),
        meta:{
          tab:'工程背景'
        }
      },
      {
        path: 'gcxm',
        name: 'gcxm',
        component: () => import('../views/gcxm'),
        meta:{
          tab:'工程项目'
        }
      },
      {
        path: 'xmjs',
        name: 'xmjs',
        component: () => import('../views/xmjs'),
        meta:{
          tab:'项目介绍'
        }
      },
      {
        path: 'hjyx',
        name: 'hjyx',
        component: () => import('../views/hjyx'),
        meta:{
          tab:'环境影响评估'
        }
      },
      {
        path: 'hxjs',
        name: 'hxjs',
        component: () => import('../views/hxjs'),
        meta:{
          tab:'工程核心技术'
        }
      },
      {
        path: 'xcdp',
        name: 'xcdp',
        component: () => import('../views/xcdp'),
        meta:{
          tab:'社区联系'
        }
      },
      {
        path: 'lxwm',
        name: 'lxwm',
        component: () => import('../views/lxwm'),
        meta:{
          tab:'联系我们'
        }
      },
      {
        path: 'moreNews',
        name: 'moreNews',
        component: () => import('../views/moreNews'),
        meta:{
          tab:'最新消息'
        }
      },
      {
        path: 'newDetail',
        name: 'newDetail',
        component: () => import('../views/newDetail'),
        meta:{
          tab:'消息详情'
        }
      },
      {
        path: 'hjyxpg',
        name: 'hjyxpg',
        component: () => import('../views/hjyxpg'),
        meta:{
          tab:'环境影响评估'
        }
      },
      {
        path: 'main/:id',
        name: 'main',
        component: ()=>import('../views/main'),
        meta:{
          tab:''
        }
      }
    ]
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
})


export default router

function xxx01MetaTags() {
  // meta标签
 const existingMeta = document.querySelector('meta[name="viewport"]');
 existingMeta.content = 'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no';
}
function xxx02MetaTags() {
  // meta标签
 const existingMeta = document.querySelector('meta[name="viewport"]');
 existingMeta.content = 'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no';
}
function MetaTags() {
  // meta标签
 const existingMeta = document.querySelector('meta[name="viewport"]');
 existingMeta.content = 'width=device-width, initial-scale=1'
}

xxx01MetaTags()
