import { reactive } from "vue";
import router from '../router'
export const stores = reactive({
  isLoading: false,
  lang:'',
  updateLoadingStatue(status){
    this.isLoading = status;
  },
  getLang(lang){
    let str = "";
    // const lang = window.translate.language.getCurrent();
    if (lang === "english") {
      str = 'EN';
    } else if (lang === "chinese_traditional") {
      str = 'TC';
    } else {
      str = 'SC';
    }
    return str
  },
  updateRoute(lang){
    let str = "";
    if (lang === "english") {
      str = 'EN';
    } else if (lang === "chinese_traditional") {
      str = 'TC';
    } else {
      str = 'SC';
    }
    const route = router.currentRoute
    const oldRoute = router.currentRoute
    const times = new Date()
    if(route.path.indexOf('main') !== -1){
      router.push({name:'main',params:{id:oldRoute.params.id, lang: str}, query:{...oldRoute.query,times}})
    }
    if(route.path.indexOf('index') !== -1){
      router.push({name:'home',params:{ lang: str}})
    }
    location.reload()
  }
});
