import Axios from 'axios'

const instance = Axios.create({
  baseUrl: '/api'
})

instance.interceptors.request.use(
  (config) => {
    // console.log(config);
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

instance.interceptors.response.use(
  (res) => {
    return res.data
  },
  (err) => {
    return Promise.reject(err)
  }
)

export default instance
