<template>
  <div class="bg-light-blue">
    <Bgcontainer></Bgcontainer>
    <router-view :key="$route.fullPath"></router-view>
<!--    <Cooperation></Cooperation>-->
    <Footer></Footer>
  </div>
</template>

<script>
import Bgcontainer from '@/components/bgCotainer'
// import Cooperation from '@/components/cooperation'
import { getConfig } from '@/axios/api'
import Footer from '@/components/footer'
export default {
  name: 'indexPage',
  components: {
    Bgcontainer,
    // Cooperation,
    Footer
  },
  data () {
    return {
      config: {}
    }
  },
  mounted () {
  },
  created () {
    // this.getConfig()
  },
  methods: {
    getConfig () {
      getConfig().then(res => {
        this.config = res.data
        sessionStorage.setItem('pageConfig', JSON.stringify(res.data))
      })
    }
  }
}
</script>
<style lang="less" scoped>

</style>
